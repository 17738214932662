<template>
	<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 left_side_bar e_chart_sidebar px-0" v-once>
		<div class="sticky-scroll-box">
			<ul class="side-bar-menu" data-widget="tree">
				<li :class="{ 'active': this.$route.name === 'AccountSettings' }" >
                    <router-link :to="{ name: 'AccountSettings'}"> Account Settings</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AccountSettings'">
				</li>
				<li :class="{ 'active': this.$route.name === 'clinicSettings' }" >
                    <router-link :to="{ name: 'clinicSettings'}"> Clinic Settings</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'clinicSettings'">
				</li>
				<li :class="{ 'active': this.$route.name === 'managementSettings' }" >
                    <router-link :to="{ name: 'managementSettings'}"> Management Settings</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'managementSettings'">
				</li>
				<li :class="{ 'active': this.$route.name === 'hrAnalysisReports' }" >
                    <router-link :to="{ name: 'hrAnalysisReports'}"> HR Analysis Report</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'hrAnalysisReports'">
				</li>
				<li :class="{ 'active': this.$route.name === 'billingCustomCode' }" >
                    <router-link :to="{ name: 'billingCustomCode'}"> Billing Custom Codes</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'billingCustomCode'">
				</li>
				<li :class="{ 'active': this.$route.name === 'thirdPartyIntegrations' }" >
                    <router-link :to="{ name: 'thirdPartyIntegrations'}"> Third-Party Integrations</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'thirdPartyIntegrations'">
				</li>
				<li :class="{ 'active': this.$route.name === 'paymentPreferences' }" >
                    <router-link :to="{ name: 'paymentPreferences'}"> Payment Preferences</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'paymentPreferences'">
				</li>
				<li :class="{ 'active': this.$route.name === 'auditLogs' }" >
                    <router-link :to="{ name: 'auditLogs'}"> Audit Logs</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'auditLogs'">
				</li>
				<li :class="{ 'active': this.$route.name === 'dataManagement' }" >
                    <router-link :to="{ name: 'dataManagement'}"> Data Management</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'dataManagement'">
				</li>
            </ul>
        </div>
    </div>
</template>